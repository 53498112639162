import React from 'react'
import Link from 'gatsby-link'
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"

const NavLink = props => {
  if (!props.test) {
    return <Link to={props.url}>{props.text}</Link>
  } else {
    return <span>{props.text}</span>
  }
}

const IndexPage = ({ data, pageContext }) => {
  const { group, index, first, last, pageCount } = pageContext
  const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString()
  const nextUrl = (index + 1).toString()

  return (
    <Layout>
      <SEO title="Analytics and Data Science essays" />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Come and learn with us about analytics and data science" />
        <link rel="canonical" href={'blog'} />
      </Helmet>
      <SEO title="Home" />
      <h4>{pageCount} Pages</h4>

      {group.map(({ node }) => (
        <div key={node.id} className="blogListing">
          {/*  Here it goes the post listed */}
          <div key={node.slug}>
            <h3><Link to={node.slug}>{node.title}</Link></h3>
            <div dangerouslySetInnerHTML={{__html: node.excerpt}} />
            {node.date}
          </div>
        </div>
      ))}
      <div className="previousLink">
        <NavLink test={first} url={'./posts/' + previousUrl} text="Go to Previous Page" />
      </div>
      <div className="nextLink">
        <NavLink test={last} url={'./posts/' + nextUrl} text="Go to Next Page" />
      </div>
    </Layout>
  )
}
export default IndexPage